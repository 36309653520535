import React from 'react'
import Showdown from 'showdown'
import { MDBBtn, MDBBtnGroup } from 'mdbreact'

export default class Requirements extends React.Component {
  myRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      btnActive: this.props.btnId,
    }
  }

  componentDidMount() {
    this.setState({
      btnActive: this.props.btnId,
    })
    this.handleClick(this.props.btnId)
  }

  handleClick(value) {
    this.setState({ btnActive: value })
  }

  render() {
    const converter = new Showdown.Converter()
    return (
      <>
        {this.props.data.length > 1 && (
          <MDBBtnGroup>
            {this.props.data.map((buttons, index) => (
              <MDBBtn
                className={`${ this.state.btnActive === buttons.subtitle ? 'btn-mdb-color' : 'btn-products' }`}
                value={buttons.subtitle}
                onClick={() => this.handleClick(buttons.subtitle)}
                key={index}
              >
                {buttons.subtitle}
              </MDBBtn>
            ))}
          </MDBBtnGroup>
        )}

        {this.props.data.map((requirements, index) => (
          <div
            key={index}
            id={requirements.subtitle.toLowerCase().replace(/ /g, '-')}
            style={{ display: this.state.btnActive === requirements.subtitle ? 'block' : 'none', }}
          >
            <div
              className="text-medium text-gray-dark divlink"
              dangerouslySetInnerHTML={{ __html: converter.makeHtml(requirements.description), }}
              ref={index === 0 ? this.myRef : null}
            />
          </div>
        ))}
      </>
    )
  }
}
