import React, { Fragment, useState } from 'react'
import Showdown from 'showdown'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import Faqs from '../components/faqs'
import Card from '../components/card'
import Cta from '../components/cta'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faList, faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import { MDBRow, MDBCol, MDBContainer, MDBAnimation, MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBCarouselCaption, MDBBtn, MDBBtnGroup, } from 'mdbreact'
import CardIcons from '../components/cardIcons.js'

library.add(fat)

const ProductTemplate = ({ data }) => {
  const post = data.markdownRemark
  const heroImage = data.heroImage
  const converter = new Showdown.Converter()

  // State to manage the visibility of the section
  const [isVisible, setIsVisible] = useState(false)
  const [isModelVisible, setIsModelVisible] = useState(false)

  // Function to toggle the visibility state
  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  const toggleModelVisibility = () => {
    setIsModelVisible(!isModelVisible)
  }

  const toggleNothing = () => {}

  return (
    <div id="sub-page">
      <Layout>
        <Hero
          class="intro-65"
          image={heroImage?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          title={`${post.frontmatter.title} - ${post.frontmatter.name}`}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <div className={`font-w-400 text-xs-extra-large divlink ${ post.html ? 'text-opening-para' : 'text-opening-para-mb0' }`} dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.openingpara), }} />
                <div className="mt-3 richtext divlink" dangerouslySetInnerHTML={{ __html: post.html}} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.bragroll && (
          <section className="bg-white no-top">
            <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">{post.frontmatter.bragroll.title}</h2>
                {post.frontmatter.bragroll.subtitle && (
                  <p className="text-medium">{post.frontmatter.bragroll.subtitle}</p>
                )}
                {post.frontmatter.bragroll.card && (
                  <MDBRow className="pt-5">
                    {post.frontmatter.bragroll.card.map((cards, index) => (
                      <CardIcons
                        key={index}
                        collg="4"
                        colmd="12"
                        data={cards}
                      />
                    ))}
                  </MDBRow>
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        )}

        {post.frontmatter.carousel && (
          <>
            {post.frontmatter.carousel.map((carousels, index) => (
              <section className="bg-white no-top" key={index}>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol md="12">
                      <p className="font-alt font-w-700 letter-spacing-1 pb-5 title-medium title-large">{carousels.title}</p>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCarousel
                      activeItem={1}
                      length={post.frontmatter.carousel[0].prodimage.length}
                      showControls={true}
                      showIndicators={true}
                      interval={3000}
                      className="no-flex text-center branding"
                    >
                      <MDBCarouselInner>
                        {carousels.prodimage.map((prodimages, index) => {
                          return (
                            <MDBCarouselItem itemId={index + 1} key={index}>
                              <div>
                                <div className="branding-img-wrapper">
                                  <GatsbyImage
                                    image={prodimages.image.childImageSharp .gatsbyImageData}
                                    className="mb-4 px-3 d-block w-100"
                                    alt={prodimages.alttext}
                                  />
                                  <MDBCarouselCaption>
                                    {prodimages.title && (
                                      <p className="title-small font-w-400 " style={{ whiteSpace: 'pre-wrap' }}>{prodimages.title}</p>
                                    )}
                                  </MDBCarouselCaption>
                                </div>
                              </div>
                            </MDBCarouselItem>
                          )
                        })}
                      </MDBCarouselInner>
                    </MDBCarousel>
                  </MDBRow>
                </MDBContainer>
              </section>
            ))}
          </>
        )}

        <section className="bg-light-blue">
          <MDBContainer>
            <MDBRow>
              <MDBCol className="text-center text-md-left">
                <MDBBtnGroup>
                  <MDBBtn className={`${!isVisible ? 'btn-mdb-color' : 'btn-products' }`} onClick={isVisible ? toggleVisibility : toggleNothing}><FontAwesomeIcon icon={faStar} className="mr-2" />Features</MDBBtn>
                  {post.frontmatter.specification && (
                  <MDBBtn className={`${ isVisible ? 'btn-mdb-color' : 'btn-products' }`} onClick={!isVisible ? toggleVisibility : toggleNothing}><FontAwesomeIcon icon={faList} className="mr-2" />Specifications</MDBBtn>
                  )}
                </MDBBtnGroup>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="text-center text-md-left pt-4">
                {post.frontmatter.section.map((sections, index) => (
                  <>
                    <input className="form-check-input" type="radio" name="radio" id={`model${index}`} defaultChecked={index === 0} onClick={() => {if (index === 0) {isModelVisible ? toggleModelVisibility() : toggleNothing()} else {!isModelVisible ? toggleModelVisibility() : toggleNothing()}}} />
                    <label className="text-medium pr-3" for={`model${index}`}>{sections.title}</label>
                  </>
                ))}                                
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.section && !isVisible && (
          <>
            <section className="bg-light-blue-features mt-md-n5">
              <MDBContainer>
                  {post.frontmatter.section.map((sections, index) => (
                    <Fragment key={index}> 
                      {((!isModelVisible && index === 0) || (isModelVisible && index === 1)) && (
                        <>
                          <MDBRow>
                            <MDBCol>
                              <h2 className="font-alt font-w-700 letter-spacing-1 title-medium title-large text-left pb-5">{sections.title} features</h2>
                            </MDBCol>
                          </MDBRow>   
                                                   
                          <MDBRow className="pt-3">
                            {sections.feature.map((features, index) => (
                              <MDBCol md="6" key={index} className="pb-5">
                                <MDBRow>
                                  <MDBCol md="12" className="pr-5">
                                    <div className="feature-icon">
                                      <FontAwesomeIcon icon={['fat', features.icon]} size="3x" />
                                    </div>
                                    <div className="pb-2">
                                      <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium">{features.title}</h3>
                                      <p className="text-medium text-gray-dark mt-3">{features.description}</p>
                                    </div>
                                  </MDBCol>
                                </MDBRow>  

                                <MDBRow>
                                  <MDBCol>
                                    <div className={`text-medium divlink prod-detail text-gray-dark`} dangerouslySetInnerHTML={{ __html: converter.makeHtml( features.detail ),}} />
                                  </MDBCol>
                                </MDBRow>
                              </MDBCol>
                            ))}
                          </MDBRow>
                        </>
                      )}
                    </Fragment>
                  ))}
              </MDBContainer>
            </section>
          </>
        )}

        {post.frontmatter.specification && isVisible && (
          <>
            <section className="bg-light-blue-features mt-md-n5">
              <MDBContainer>
                  {post.frontmatter.specification.map((specifications, index) => (
                      <Fragment key={index}> 
                        {((!isModelVisible && index === 0) || (isModelVisible && index === 1)) && (
                          <>
                            <MDBRow>
                              <MDBCol>
                                <h2 className="font-alt font-w-700 letter-spacing-1 title-medium title-large text-left pb-5">{specifications.title} specifications</h2>
                              </MDBCol>
                            </MDBRow>

                            <MDBRow className="pt-3">
                              {specifications.feature.map((features, index) => (
                                <MDBCol md="6" key={index} className="pb-5">
                                  <MDBRow>
                                    <MDBCol md="12" className="pr-5">
                                      <div className="feature-icon">
                                        <FontAwesomeIcon icon={['fat', features.icon]} size="3x" />
                                      </div>
                                      <div className="pb-2">
                                        <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium">{features.title}</h3>
                                        <p className="text-medium text-gray-dark mt-3">{features.description}</p>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>

                                  <MDBRow>
                                    <MDBCol>
                                      <div className={`text-medium divlink prod-detail text-gray-dark`} dangerouslySetInnerHTML={{ __html: converter.makeHtml(features.detail),}} />
                                    </MDBCol>
                                  </MDBRow>
                                </MDBCol>
                              ))}
                            </MDBRow>
                          </>
                        )}
                      </Fragment>
                    )
                  )}
              </MDBContainer>
            </section>
          </>
        )}

        {post.frontmatter.footnote && (
          <section className={`${ post.frontmatter.faq ? 'bg-light-blue no-top mt-n5' : 'bg-white' }`}>
            <MDBContainer>
              {post.frontmatter.faq !== null && (
                <MDBRow>
                  <MDBCol>
                    <hr className="md-line" />
                  </MDBCol>
                </MDBRow>
              )}
              <MDBRow>
                <MDBCol>
                  <h2 className="font-alt font-w-700 letter-spacing-1 mb-5 title-xs-medium title-large">Footnotes</h2>
                  <div className="mt-3 richtext divlink" dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.footnote),}} />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
        )}

        {post.frontmatter.faq && post.frontmatter.faq.length >= 1 && (
          <section className="bg-white">
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" className="pb-5">
                  <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-large">Frequently asked questions (FAQ)</h2>
                </MDBCol>
              </MDBRow>
              {post.frontmatter.faq && 
                <Faqs items={post.frontmatter.faq} />
              }
            </MDBContainer>
          </section>
        )}

        {post.frontmatter.helpfulresources && (
          <section className="bg-white-grey-gradient">
            <MDBAnimation reveal type="fadeIn" duration="1587ms">
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left pb-5"> {post.frontmatter.helpfulresources.title} <span id="helpful-resources" className="anchor">&nbsp;</span></h2>
                {post.frontmatter.helpfulresources.subtitle && (
                  <p className="text-medium">{post.frontmatter.helpfulresources.subtitle}</p>
                )}
                {post.frontmatter.helpfulresources.helpfulres && (
                  <MDBRow className="pt-5">
                    {post.frontmatter.helpfulresources.helpfulres.map((helpfulres, index) => (
                        <Card
                          key={index}
                          collg="4"
                          colmd="6"
                          height="10.5rem"
                          title={helpfulres.title}
                          subtitle={helpfulres.subtitle}
                          description={helpfulres.description}
                          multilink={helpfulres.multilink}
                          image={helpfulres.image.childImageSharp.gatsbyImageData}
                          alttext={helpfulres.alttext}
                          placement={helpfulres.placement}
                          link={helpfulres.link ? helpfulres.link : helpfulres.document.publicURL}
                          titleclass="title-small"
                          descriptionClass="text-card-small py-2"
                        />
                      )
                    )}
                  </MDBRow>
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        )}

        {post.frontmatter.cta && (
          <Cta
            ctatitle={post.frontmatter.cta.title}
            ctadescription={post.frontmatter.cta.description}
            ctalink={post.frontmatter.cta.link}
            ctalinktext={post.frontmatter.cta.linktext}
          />
        )}
      </Layout>
    </div>
  )
}
export default ProductTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  const heroImage = data.heroImage
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.title}
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "products" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description
        }
        template
        name
        title
        subtitle
        ctatext
        ctaslug
        openingpara
        footnote
        bragroll {
          title
          subtitle
          card {
            title
            subtitle
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            features {
              linktext
              link
              class
              btnactive
            }
          }
        }
        section {
          title
          feature {
            title
            description
            icon
            detail
          }
        }
        specification {
          title
          feature {
            title
            description
            icon
            detail
          }
        }
        carousel {
          title
          prodimage {
            title
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            alttext
          }
        }
        faq {
          question
          answer
        }
        helpfulresources {
          title
          subtitle
          helpfulres {
            title
            subtitle
            description
            multilink
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            document {
              publicURL
            }
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
    }
    heroImage: file(name: { eq: "product-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`
