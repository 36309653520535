import React from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer, MDBAnimation } from 'mdbreact'
import Layout from '../../../components/layout'
import Hero from '../../../components/heroSubpage'
import SEO from '../../../components/seo'
import OfficeLocations from '../../../components/columnAlternatingOffices'


const FactSheet = ({ data }) => {
  const post = data.markdownRemark
  return (
    <div id="sub-page">
      <Layout>
        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}             
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
              <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{__html: post.frontmatter.openingpara}}
                />
                <div
                  className="mt-3 richtext images divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <section className="bg-light-blue" id="locations">
            <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <h3 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-large pb-5">
                  {post.frontmatter.locations.title}
                </h3>
                {post.frontmatter.locations.offices.map((officelocations, index) => {
                    return (
                      <OfficeLocations
                        key={index}
                        office={officelocations.office}
                        address={officelocations.address}
                        tel={officelocations.tel}
                        link={officelocations.link}
                        linktext={officelocations.linktext}
                        lat={officelocations.lat}
                        lng={officelocations.lng}
                        placement={officelocations.placement}
                      />
                    )
                  }
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>

      
      </Layout>
    </div>
  )
}
export default FactSheet

export function Head({data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {  
    markdownRemark(
      frontmatter: { name: { eq: "Fact sheet" } }
    ) {
      frontmatter {
        meta {
          title
          description 
        }        
        title
        subtitle
        openingpara
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        locations {
            title
            offices {
              office
              address
              tel
              link
              linktext
              lat
              lng
              placement
            }
          }
        }
      html
    }
  }
`
