import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBDatePicker, toast, ToastContainer, } from 'mdbreact'
import { Zoom } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { Link } from 'gatsby'
import moment from 'moment'

const validationSchema = Yup.object().shape({
  customername: Yup.string()
    .min(2, 'Customer name must be 2 characters at minimum')
    .required('Name is required'),
  customeremail: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'),
  age: Yup.string()
    .min(2, 'Age must be 2 characters at minimum')
    .required('Age is required'),
  user: Yup.string().required('Type of user is required'),
  address: Yup.string()
    .min(2, 'Address must be 5 characters at minimum')
    .required('Address is required'),
  city: Yup.string()
    .min(2, 'City must be 2 characters at minimum')
    .required('City is required'),
  state: Yup.string()
    .min(2, 'State must be 2 characters at minimum')
    .required('State is required'),
  zip: Yup.string()
    .min(2, 'Zip/postcode must be 5 characters at minimum')
    .required('Zip/postcode is required'),
  country: Yup.string()
    .min(2, 'Country must be 2 characters at minimum')
    .required('Country is required'),
  productcode: Yup.string().required('Productcode is required'),
  serialnumber: Yup.string().required('Serial number is required'),
  // purchasedate: Yup.date().typeError('The value must be a date (YYYY-MM-DD)')
})

const formUrl =
  'https://script.google.com/macros/s/AKfycbzmM1SefmzSoNv-eM731pNj59QAuBo1fjPJ_IRZLPyeosOk63nJshQhGx0oCsdt-T4S/exec'

const messageConfirm = () => {
  return toast.info( "Thank you for contacting us.  We'll be in touch shortly!", { closeButton: false, position: toast.POSITION.TOP_CENTER, transition: Zoom, } )
}

class formProductRegistration extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      pageUrl: null,
      purchaseDate: new Date(),
      load: false,
      expired: 'false',
    }
  }

  getPurchaseDate = (value) => {
    this.setState({
      purchaseDate: value,
    })
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        load: true,
        pageUrl: location.href,
      })
    })
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol lg="9" md="12" className="md-0 mb-5">
            <Formik
              initialValues={{
                customername: '',
                customeremail: '',
                age: '',
                user: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                country: '',
                phone: '',
                productcode: '',
                serialnumber: '',
                purchasedate: '',
                purchaseplace: '',
                purchasetype: '',
                purchasefor: '',
                accessinternet: '',
                operatingsystem: '',
                computers: '',
                chooseanother: '',
                experience: '',
                subscribe: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  CustomerName: values.customername,
                  CustomerEmail: values.customeremail,
                  Age: values.age,
                  User: values.user,
                  Address: values.address,
                  City: values.city,
                  State: values.state,
                  Zip: values.zip,
                  Country: values.country,
                  Phone: values.phone,
                  ProductCode: values.productcode,
                  SerialNumber: values.serialnumber,
                  PurchaseDate: this.state.purchaseDate,
                  PurchasePlace: values.purchaseplace,
                  PurchaseType: values.purchasetype,
                  PurchaseFor: values.purchasefor,
                  AccessInternat: values.accessinternet,
                  OperatingSystem: values.operatingsystem,
                  ChooseAnother: values.chooseanother,
                  Experience: values.experience,
                  Computers: values.computers,
                  Subscribe: values.subscribe,
                }
                try {
                  const response = axios({
                    method: 'get',
                    url: `${formUrl}?timestamp=&url=${encodeURIComponent(this.state.pageUrl)}&name=${encodeURIComponent( values.customername )}&email=${encodeURIComponent( values.customeremail )}&age=${encodeURIComponent( values.age )}&user=${encodeURIComponent( values.user )}&street=${encodeURIComponent( values.address )}&city=${encodeURIComponent( values.city )}&state=${encodeURIComponent( values.state )}&zip=${encodeURIComponent( values.zip )}&country=${encodeURIComponent( values.country )}&phone=${encodeURIComponent( values.phone )}&productcode=${encodeURIComponent( values.productcode )}&serialnumber=${encodeURIComponent( values.serialnumber )}&purchasedate=${encodeURIComponent(moment(this.state.purchaseDate).format('MMM DD, YYYY'))}&purchaseplace=${encodeURIComponent( values.purchaseplace )}&purchasetype=${encodeURIComponent( values.purchasetype )}&purchasefor=${encodeURIComponent( values.purchasefor )}&accessinternet=${encodeURIComponent(values.accessinternet)}&operatingsystem=${encodeURIComponent(values.operatingsystem)}&computers=${encodeURIComponent(values.computers.toString())}&chooseanother=${encodeURIComponent(values.chooseanother)}&experience=${encodeURIComponent(values.experience)}&subscribe=${encodeURIComponent(values.subscribe)}`, })
                  this.setState({ values: '', expired: 'true' })
                  resetForm()
                  messageConfirm()
                } catch (e) {
                  console.log(`Axios request failed: ${e}`)
                }
                setSubmitting(false)
              }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form
                  name="contact"
                  method="post"
                  action=""
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />
                  <section className="form-section rounded">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">
                            General information
                          </h2>
                          <hr />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="6">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="customername">Customer name <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="text"
                            id="customername"
                            name="customername"
                            aria-label="customername"
                            value={values.customername}
                            placeholder="Your name"
                            className={`form-control ${ touched.customername && errors.customername ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="customername" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol md="6">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="customeremail">Customer email <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="text"
                            id="customeremail"
                            name="customeremail"
                            aria-label="customeremail"
                            value={values.customeremail}
                            placeholder="Your email address"
                            className={`form-control ${ touched.customeremail && errors.customeremail ? 'is-invalid' : '' }`}
                          />                            
                          <ErrorMessage component="div" name="customeremail" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="4">
                        <div className="mb-4 grey-text font-w-700 text-medium">
                          <label htmlFor="phone">Phone</label>
                          <Field
                            type="text"
                            name="phone"
                            value={values.phone}
                            placeholder="Your phone number"
                            className={`form-control ${ touched.phone && errors.phone ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="phone" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol md="4">
                        <div className="mb-4 grey-text font-w-700 text-medium">
                          <label htmlFor="age">Age <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="text"
                            name="age"
                            value={values.age}
                            placeholder="Your age"
                            className={`form-control ${ touched.age && errors.age ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="age" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol md="4">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="user">Type of user <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="user"
                            name="user"
                            id="user"
                            as="select"
                            value={values.user}
                            placeholder="Please select an option"
                            className={`form-control ${ touched.user && errors.user ? 'is-invalid' : '' }`}
                          >
                            <option value="Please select" label="Please select">Please select</option>
                            <option value="Business" label="Business">Business</option>
                            <option value="Home" label="Home">Home</option>
                            <option value="Home office" label="Home office">Home office</option>
                            <option value="All" label="All">All</option>
                          </Field>
                          <ErrorMessage component="div" name="user" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-700 text-medium">
                          <label htmlFor="address">Address <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="textarea"
                            component="textarea"
                            name="address"
                            value={values.address}
                            placeholder="Enter your address"
                            className={`form-control ${ touched.address && errors.address ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="address" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="4">
                        <div className="mb-4 grey-text font-w-700 text-medium">
                          <label htmlFor="city">City <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="text"
                            name="city"
                            value={values.city}
                            placeholder="Your city of residence"
                            className={`form-control ${ touched.city && errors.city ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="city" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol md="4">
                        <div className="mb-4 grey-text font-w-700 text-medium">
                          <label htmlFor="state">State/province <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="text"
                            name="state"
                            value={values.state}
                            placeholder="Your state of residence"
                            className={`form-control ${ touched.state && errors.state ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="state" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol md="4">
                        <div className="mb-4 grey-text font-w-700 text-medium">
                          <label htmlFor="zip">Zip/postcode <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="text"
                            name="zip"
                            value={values.zip}
                            placeholder="Your zip/postcode of residence"
                            className={`form-control ${ touched.zip && errors.zip ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="zip" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-700 text-medium">
                          <label htmlFor="country">Country <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="text"
                            name="country"
                            value={values.country}
                            placeholder="Your country of residence"
                            className={`form-control ${ touched.country && errors.country ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="country" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </section>

                  <section className="form-section rounded">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">
                            Product information
                          </h2>
                          <hr />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="productcode">USR product code <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="productcode"
                            name="productcode"
                            id="productcode"
                            as="select"
                            value={values.productcode}
                            placeholder="Please select an option"
                            className={`form-control ${ touched.productcode && errors.productcode ? 'is-invalid' : '' }`}
                          >
                            <option value="Please select a product" label="Please select a product">Please select a product</option>
                            <option value="USR015630G" label="USR015630G"> USR015630G </option>
                            <option value="USR025630G" label="USR025630G"> USR025630G </option>
                            <option value="USR065630G" label="USR065630G"> USR065630G </option>
                            <option value="USR065637" label="USR065637"> USR065637 </option>
                            <option value="USR135630G" label="USR135630G"> USR135630G </option>
                            <option value="USR205630G" label="USR205630G"> USR205630G </option>
                            <option value="USR265670" label="USR265670"> USR265670 </option>
                            <option value="USR263093-OEM" label="USR263093-OEM"> USR263093-OEM </option>
                            <option value="USR2973-OEM" label="USR2973-OEM"> USR2973-OEM </option>
                            <option value="USR2980-OEM" label="USR2980-OEM"> USR2980-OEM </option>
                            <option value="USR325610C" label="USR325610C"> USR325610C </option>
                            <option value="USR3453C" label="USR3453C"> USR3453C </option>
                            <option value="USR3453C-REF" label="USR3453C-REF"> USR3453C-REF </option>
                            <option value="USR3500" label="USR3500"> USR3500 </option>
                            <option value="USR3510" label="USR3510"> USR3510 </option>
                            <option value="USR3513" label="USR3513"> USR3513 </option>
                            <option value="USR3516-EMU" label="USR3516-EMU"> USR3516-EMU </option>
                            <option value="USR3520" label="USR3520"> USR3520 </option>
                            <option value="USR4000" label="USR4000"> USR4000 </option>
                            <option value="USR4005" label="USR4005"> USR4005 </option>
                            <option value="USR4009" label="USR4009"> USR4009 </option>
                            <option value="USR413453C" label="USR413453C"> USR413453C </option>
                            <option value="USR4204" label="USR4204"> USR4204 </option>
                            <option value="USR4503" label="USR4503"> USR4503 </option>
                            <option value="USR4504" label="USR4504"> USR4504 </option>
                            <option value="USR4515LC" label="USR4515LC"> USR4515LC </option>
                            <option value="USR4516LC" label="USR4516LC"> USR4516LC </option>
                            <option value="USR4523" label="USR4523"> USR4523 </option>
                            <option value="USR4524" label="USR4524"> USR4524 </option>
                            <option value="USR4524-MINI" label="USR4524-MINI"> USR4524-MINI </option>
                            <option value="USR4525" label="USR4525"> USR4525 </option>
                            <option value="USR4526" label="USR4526"> USR4526 </option>
                            <option value="USR5610C" label="USR5610C"> USR5610C </option>
                            <option value="USR5630G" label="USR5630G"> USR5630G </option>
                            <option value="USR5637" label="USR5637"> USR5637 </option>
                            <option value="USR5638" label="USR5638"> USR5638 </option>
                            <option value="USR5639" label="USR5639"> USR5639 </option>
                            <option value="USR5670" label="USR5670"> USR5670 </option>
                            <option value="USR5686G" label="USR5686G"> USR5686G </option>
                            <option value="USR5686G-PRO" label="USR5686G-PRO"> USR5686G-PRO </option>
                            <option value="USR802981-OEM" label="USR802981-OEM"> USR802981-OEM </option>                          
                            <option value="USR803510" label="USR803513"> USR803510 </option>
                            <option value="USR803513" label=""> USR803513 </option>
                            <option value="USR803520" label="USR803520"> USR803520 </option>
                            <option value="USR805637" label="USR805637"> USR805637 </option>
                            <option value="USR805671" label="USR805671"> USR805671 </option>
                            <option value="USR813453C" label="USR813453C"> USR813453C </option>
                            <option value="USR815670" label="USR815670"> USR815670 </option>
                            <option value="USR843453C" label="USR843453C"> USR843453C </option>
                            <option value="USR845686G-PRO" label="USR845686G-PRO"> USR845686G-PRO </option>                                                    
                          </Field>
                          <ErrorMessage component="div" name="productcode" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="serialnumber">Serial number <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="text"
                            id="serialnumber"
                            name="serialnumber"
                            aria-label="serialnumber"
                            value={values.serialnumber}
                            placeholder="Product serial number"
                            className={`form-control ${ touched.serialnumber && errors.serialnumber ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="serialnumber" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="purchasedate">Purchase date <span style={{ color: 'red' }}>*</span></label>
                          <MDBDatePicker
                            type="text"
                            id="purchasedate"
                            name="purchasedate"
                            aria-label="purchasedate"
                            getValue={this.getPurchaseDate}
                            placeholder=""
                            disableFuture 
                            className={`form-control my-0 p-1 ${ touched.purchasedate && errors.purchasedate ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="purchasedate" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="purchaseplace">Place of purchase</label>
                          <Field
                            type="text"
                            id="purchaseplace"
                            name="purchaseplace"
                            aria-label="purchaseplace"
                            value={values.purchaseplace}
                            placeholder="Where did you purchase?"
                            className={`form-control ${ touched.purchaseplace && errors.purchaseplace ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="purchaseplace" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="purchasetype">Purchase place type</label>
                          <Field
                            type="purchasetype"
                            name="purchasetype"
                            id="purchasetype"
                            as="select"
                            value={values.purchasetype}
                            placeholder="Please select an option"
                            className={`form-control ${ touched.purchasetype && errors.purchasetype ? 'is-invalid' : '' }`}
                          >
                            <option value="Please select" label="Please select">Please select</option>
                            <option value="Retail store" label="Retail store">Retail store</option>
                            <option value="Online" label="Online">Online</option>
                            <option value="VAR/Consultant" label="VAR/Consultant">VAR/Consultant</option>
                            <option value="Internet Service Provider" label="Internet Service Provider">Internet Service Provider</option>
                            <option value="Mail order" label="Mail order">Mail order </option>
                            <option value="Phone company" label="Phone company">Phone company</option>
                            <option value="System integrator" label="System integrator">System integrator</option>
                            <option value="Other reseller" label="Other reseller">Other reseller</option>
                          </Field>
                          <ErrorMessage component="div" name="purchasetype" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="purchasefor">Did you purchase this product for</label>
                          <Field
                            type="purchasefor"
                            name="purchasefor"
                            id="purchasefor"
                            as="select"
                            value={values.purchasefor}
                            placeholder="Please select an option"
                            className={`form-control ${ touched.purchasefor && errors.purchasefor ? 'is-invalid' : '' }`}
                          >
                            <option value="Please select" label="Please select">Please select</option>
                            <option value="Home" label="Yourself, to use at home">Yourself, to use at home</option>
                            <option value="Work" label="Yourself, to use at work">Yourself, to use at work</option>
                            <option value="Gift" label="Received as a gift">Received as a gift</option>
                            <option value="Resell" label="To resell or install in a client's computer">To resell or install in a client's computer</option>
                          </Field>
                          <ErrorMessage component="div" name="purchasefor" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </section>

                  <section className="form-section rounded">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">
                            Please answer the following
                          </h2>
                          <hr />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol lg="4" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="accessinternet">Internet access</label>
                          <Field
                            type="accessinternet"
                            name="accessinternet"
                            id="accessinternet"
                            as="select"
                            value={values.accessinternet}
                            placeholder="Please select an option"
                            className={`form-control ${ touched.accessinternet && errors.accessinternet ? 'is-invalid' : '' }`}
                          >
                            <option value="How do you get online?" label="How do you get online?">How do you get online?</option>
                            <option value="56K Modem" label="56K Modem">56K Modem</option>
                            <option value="Cable" label="Cable">Cable</option>
                            <option value="DSL" label="DSL">DSL</option>
                            <option value="T1" label="T1">T1</option>
                            <option value="T3" label="T3">T3</option>
                            <option value="Satellite" label="Satellite">Satellite</option>
                            <option value="Optical" label="Optical">Optical</option>
                            <option value="Cellular" label="Cellular">Cellular</option>
                            <option value="Mobile" label="Mobile">Mobile</option>
                            <option value="Other" label="Other">Other</option>
                            <option value="I will switch" label="I will switch">I will switch</option>
                          </Field>
                          <ErrorMessage component="div" name="accessinternet" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="4" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="operatingsystem">Operating System</label>
                          <Field
                            type="operatingsystem"
                            name="operatingsystem"
                            id="operatingsystem"
                            as="select"
                            value={values.operatingsystem}
                            placeholder="Select your Operating System"
                            className={`form-control ${ touched.operatingsystem && errors.operatingsystem ? 'is-invalid' : '' }`}
                          >
                            <option value="Select your OS" label="Select your OS">Select your OS</option>
                            <option value="Linux" label="Linux">Linux</option>
                            <option value="Mac OS 7" label="Mac OS 7">Mac OS 7</option>
                            <option value="Mac OS 8" label="Mac OS 8">Mac OS 8</option>
                            <option value="Mac OS 9" label="Mac OS 9">Mac OS 9</option>
                            <option value="Mac OS X" label="Mac OS X">Mac OS X</option>
                            <option value="Windows 3.1" label="Windows 3.1">Windows 3.1</option>
                            <option value="Windows NT" label="Windows NT">Windows NT</option>
                            <option value="Windows 95" label="Windows 95">Windows 95</option>
                            <option value="Windows 98" label="Windows 98">Windows 98</option>
                            <option value="Windows Me" label="Windows Me">Windows Me</option>
                            <option value="Windows XP" label="Windows XP">Windows XP</option>
                            <option value="Windows Vista" label="Windows Vista">Windows Vista</option>
                            <option value="Windows 2000" label="Windows 2000">Windows 2000</option>
                            <option value="Windows 2003" label="Windows 2003">Windows 2003</option>
                            <option value="Windows 2008" label="Windows 2008">Windows 2008</option>
                            <option value="Windows 2011" label="Windows 2011">Windows 2011</option>
                            <option value="Windows 2012" label="Windows 2012">Windows 2012</option>                          
                            <option value="Windows 2016" label="Windows 2016">Windows 2016</option>
                            <option value="Windows 2019" label="Windows 2019">Windows 2019</option> 
                            <option value="Windows 7" label="Windows 7">Windows 7</option>                            
                            <option value="Windows 8" label="Windows 8">Windows 8</option>
                            <option value="Windows 10" label="Windows 10">Windows 10</option>
                            <option value="Windows 11" label="Windows 11">Windows 11</option>          
                            <option value="Other" label="Other">Other</option>                 
                          </Field>
                          <ErrorMessage component="div" name="operatingsystem" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="4" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="computers">Computers</label>
                          <Field
                            type="computers"
                            name="computers"
                            id="computers"
                            as="select"
                            value={values.computers}
                            placeholder="How many in your home?"
                            className={`form-control ${ touched.computers && errors.computers ? 'is-invalid' : '' }`}
                          >
                            <option value="How many in your home?" label="How many in your home?">How many in your home?</option>
                            <option value="1" label="1">1</option>
                            <option value="2-3" label="2-3">2-3</option>
                            <option value="4 or more" label="4 or more">4 or more</option>                            
                          </Field>
                          <ErrorMessage component="div" name="computers" className="invalid-feedback" />
                        </div>
                      </MDBCol>                    
                    </MDBRow>

                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="chooseanother">Would you choose another USR product</label>
                          <Field
                            type="chooseanother"
                            name="chooseanother"
                            id="chooseanother"
                            as="select"
                            value={values.chooseanother}
                            placeholder="Please select an option"
                            className={`form-control ${ touched.chooseanother && errors.chooseanother ? 'is-invalid' : '' }`}
                          >
                            <option value="Please select" label="Please select">Please select</option>
                            <option value="Definitely" label="Definitely">Definitely</option>
                            <option value="Possibly" label="Possibly">Possibly</option>
                            <option value="Not at all" label="Not at all">Not at all</option>
                          </Field>
                          <ErrorMessage component="div" name="chooseanother" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="experience">Overall experience installing and using USR product</label>
                          <Field
                            type="experience"
                            name="experience"
                            id="experience"
                            as="select"
                            value={values.experience}
                            placeholder="Please rate"
                            className={`form-control ${ touched.experience && errors.experience ? 'is-invalid' : '' }`}
                          >
                            <option value="Please rate" label="Please rate">Please rate</option>
                            <option value="Very easy" label="Very easy">Very easy</option>
                            <option value="Easy" label="Easy">Easy</option>
                            <option value="Neutral" label="Neutral">Neutral</option>                            
                            <option value="Difficult" label="Difficult">Difficult</option>
                            <option value="Very difficult" label="Very difficult">Very difficult</option>
                          </Field>
                          <ErrorMessage component="div" name="experience" className="invalid-feedback" />
                        </div>
                      </MDBCol>                    
                    </MDBRow>
                  </section>

                  <hr />

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                        To receive occasional updates about USR's products,
                        services and news, please tick the box below. You can
                        unsubscribe at any time.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mt-3 mb-4 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="subscribe"
                          name="subscribe"
                          aria-label="subscribe"
                          className={`form-check-input ${ touched.subscribe && errors.subscribe ? 'is-invalid' : '' }`}
                        />
                        <label htmlFor="subscribe">
                          Yes, I would like to receive communications from USR.
                        </label>
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-400 text-small">
                        For details on how your data is stored and used, please visit our <Link to="/privacy-statement/" className="effect">Privacy Statement</Link>.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12" className="mb-4">
                      <div style={{ paddingTop: '1rem' }}>
                        <button
                          type="submit"
                          className="mt-4 btn btn-medium btn-unicom"
                          style={{ display: 'block' }}
                          disabled={isSubmitting ? true : false}
                        >
                          {isSubmitting ? 'Please wait...' : 'Submit'}
                        </button>
                        <ToastContainer
                          transition={Zoom}
                          hideProgressBar={true}
                          newestOnTop={true}
                          autoClose={5000}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default formProductRegistration