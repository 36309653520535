import React from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLocationDot, faBuilding, faLightbulbGear } from '@fortawesome/pro-solid-svg-icons'

const CaseStudiesTemplate = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />
      </div>

      <section className="bg-white">
        {(post.frontmatter.user || post.frontmatter.location || post.frontmatter.usertype || post.frontmatter.product) &&
          <MDBContainer className="mb-lg-5 mb-0">
            <div className="mx-lg-auto font-alt font-w-400 text-gray-light text-medium text-xs-small letter-spacing-1 py-4 pt-sm-0">
              <MDBRow>
                {post.frontmatter.user && 
                  <MDBCol lg="auto" md="12" xs="12" className="mb-3 pb-lg-0"> <FontAwesomeIcon icon={faUser} className="mr-2" /> {post.frontmatter.user}</MDBCol>
                }

                {post.frontmatter.location && 
                  <MDBCol lg="auto" md="12" xs="12" className="mb-3 pb-lg-0"> <FontAwesomeIcon icon={faLocationDot} className="mr-2" /> {post.frontmatter.location}</MDBCol>
                }      

                {post.frontmatter.usertype && 
                  <MDBCol lg="auto" md="12" xs="12" className="mb-3 pb-lg-0"> <FontAwesomeIcon icon={faBuilding} className="mr-2" /> {post.frontmatter.usertype}</MDBCol>
                }

                {post.frontmatter.product && 
                  <MDBCol lg="auto" md="12" xs="12" className="mb-3 pb-lg-0"> <FontAwesomeIcon icon={faLightbulbGear} className="mr-2" />{post.frontmatter.product}</MDBCol>
                }      
              </MDBRow>
            </div>
          </MDBContainer>
        }

        <MDBContainer>
          <MDBRow>
              <MDBCol>
                <div className="richtext divlink blog-content" dangerouslySetInnerHTML={{ __html: post.html }} />
              </MDBCol>
           </MDBRow>
         </MDBContainer>
      </section>

    </Layout>
  )
}
export default CaseStudiesTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "case-studies" } }
    ) {
      fields {
        slug 
      }
      html
      frontmatter {
        meta {
          title 
          description
        }
        title
        subtitle
        user
        location
        usertype
        product
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
      }
    }
  }
`