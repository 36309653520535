import React from 'react'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'

library.add(fat)

const EducationTemplate = ({ data }) => {
  const post = data.markdownRemark

  function isActive( {isCurrent} ) {
    return isCurrent ? {className: "edu-active"} : null
}

  return (
    <div id="sub-page">
      <Layout>
        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol lg="8">
                <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara, }}
                />
                <div
                  className="mt-3 richtext images divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
              {post.frontmatter.headline && (
                <MDBCol lg="4" md="12" className="pl-lg-5">
                    <div className="bg-light-blue rounded p-3 sticky-menu">
                        {post.frontmatter.headline.map((headlines, index) => (
                          <div className="headline-tile mt-lg-3 item-headline" key={index} >
                            <div className="headline-icon">
                                <FontAwesomeIcon
                                icon={['fat', headlines.icon]}
                                size="2x"
                                className="fa-fw"
                                />
                            </div>
                            <div className="headline-content">
                                <h3 className="font-alt font-w-700 letter-spacing-1 text-xs-medium text-medium-small-icon">
                                    <Link to={headlines.link} getProps={isActive} className="effect-noline">{headlines.title}</Link>
                                </h3>
                            </div>
                          </div>
                        ))}
                    </div>                        
                </MDBCol>
              )}
            </MDBRow>
          </MDBContainer>
        </section>
      </Layout>
    </div>
  )
}
export default EducationTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "education" } }
    ) {
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        openingpara
        alttext
        ctatext
        ctaslug
        image {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
        headline {
          title
          icon
          link
        }
      }
      html
    }
  }
`
