import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import SolutionBulletsHorizontal from '../components/solutionBulletsHorizontal'
import AltList from '../components/altList'

import Card from '../components/card'

library.add(fat)

const About = ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white pb-0">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara }}
                />

                {post.html != null && (
                  <div
                    className="mt-3 richtext divlink"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                )}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.section && 
        <>
        {post.frontmatter.section.map((sections, index) => (
          <>
            {sections.type === 'bullets horizontal' && (
              <SolutionBulletsHorizontal key={index} data={sections} sectionClass={sections.class} sectionCenter={sections.center} />
            )}
          </>
        ))}
        </>
        }

        {post.frontmatter.aboutusr&&
          <section className="bg-light-blue">
            <MDBContainer>
              <div>
                {post.frontmatter.aboutusr.map((aboutus, index) => {
                  return (
                    <AltList
                      key={index}
                      title={aboutus.title}
                      image={aboutus.image.childImageSharp.gatsbyImageData}
                      alt={aboutus.alt}
                      placement={aboutus.placement}
                      bullets={aboutus.bullets}
                    />
                  )
                })}
              </div>
            </MDBContainer>
          </section>
        }        

        {post.frontmatter.helpfulresources && (
          <section className="bg-white-grey-gradient">
            <MDBAnimation reveal type="fadeIn" duration="1587ms">
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                  {' '}
                  {post.frontmatter.helpfulresources.title}{' '}
                </h2>
                <p className="text-medium">
                  {post.frontmatter.helpfulresources.subtitle}
                </p>
                {post.frontmatter.helpfulresources.helpfulres && (
                  <MDBRow className="pt-5">
                    <>
                      {post.frontmatter.helpfulresources.helpfulres.map(
                        (helpfulres) => {
                          return (
                            <Card
                              collg="4"
                              colmd="6"
                              height="9.5rem"
                              title={helpfulres.title}
                              subtitle={helpfulres.subtitle}
                              description={helpfulres.description}
                              image={ helpfulres.image.childImageSharp.gatsbyImageData }
                              alttext={helpfulres.alttext}
                              placement={helpfulres.placement}
                              link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                              titleclass="title-small"
                              descriptionClass="text-card-small py-2"
                            />
                          )
                        }
                      )}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        )}

      </main>
    </Layout>
  )
}
export default About

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}   
      canonical={location.pathname} 
    />   
  )
}

export const query = graphql`
  query {     
    markdownRemark(frontmatter: { name: { eq: "About" } }) {
      frontmatter {
        meta {
          title
          description 
        }        
        name
        title
        subtitle
        openingpara
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        section {
          title
          class
          type
          feature {
            title
            icon
            description
          }
        }  
        aboutusr {
          title
          image {
            childImageSharp {
              gatsbyImageData(width: 960, quality: 90) 
            }
          }
          placement
          alt
          bullets {
            text
          }
        }        
        helpfulresources {
          title
          subtitle
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            document {
              publicURL
            }
          }
        }                        
      }
      html
    }
  }
`