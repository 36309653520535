import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'
import Showdown from 'showdown'
// import SupportCountries from '../../components/cardIconsSupport'
import { removeURLParameter } from '../../utils/helpers.js'

const Support = ({ data, location }) => {
  const post = data.markdownRemark
  const converter = new Showdown.Converter()

  let url = new URL(typeof window !== 'undefined' ? window.location.href : data.site.siteMetadata.siteUrl + location.pathname)
  removeURLParameter(url, 'prod')
  
  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
          alt={post.frontmatter.alttext}
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBContainer>
            {/* <MDBRow className="pb-5">
                    <MDBCol>
                      <a href="http://www.usr-emea.com/support/s-main-menu.asp?loc=frnc" target="_blank" className="effect" rel="noopener">France</a>&nbsp;|&nbsp;
                      <a href="http://www.usr-emea.com/support/s-main-menu.asp?loc=grmy" target="_blank" className="effect" rel="noopener">Germany</a>&nbsp;|&nbsp;
                      <a href="http://www.usr-emea.com/support/s-main-menu.asp?loc=itly" target="_blank" className="effect" rel="noopener">Italy</a>&nbsp;|&nbsp;
                      <a href="http://www.usr-emea.com/support/s-main-menu.asp?loc=span" target="_blank" className="effect" rel="noopener">Spain</a>&nbsp;|&nbsp;
                      <a href="http://www.usr-emea.com/support/s-main-menu.asp?loc=unkg" target="_blank" className="effect" rel="noopener">UK</a>&nbsp;|&nbsp;
                      <a href="http://www.usr-emea.com/support/s-main-menu.asp?loc=emea" target="_blank" className="effect" rel="noopener">All other European</a>&nbsp;|&nbsp;
                      <a href="https://www.usr.com/international" target="_blank" className="effect" rel="noopener">All other regions</a>
                  </MDBCol>
                </MDBRow> */}
            <MDBRow>
              <MDBCol>
                <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara, }}
                />
                <div
                  className="mt-3 richtext divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>        

        {post.frontmatter.section && (
          <>
            {post.frontmatter.section.map((sections, index) => (
              <section className="bg-light-blue" key={index}>
                <MDBAnimation type="fadeIn" duration="1587ms">
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol>
                        <h3 className="font-alt font-w-700 letter-spacing-1 pb-5 title-xs-medium title-large">{sections.title}</h3>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      {sections.feature.map((features, index) => (
                        <MDBCol lg="6" md="12" className="pb-card d-flex" key={index}>
                          <div className="card flex-fill outline">
                            <div className="card-body card-hover">
                              <div className="font-alt font-w-700 letter-spacing-1 text-medium pb-2">
                                <h3 className="font-alt font-w-700 pt-4 px-3 letter-spacing-1 title-small title-xs-medium">{features.title}</h3>
                              </div>
                              <hr className="card-line" />
                              <div
                                className="mt-3 richtext divlink px-3"
                                style={{ whiteSpace: 'pre-wrap' }}
                                dangerouslySetInnerHTML={{ __html: converter.makeHtml( features.description ), }}
                              />
                            </div>
                          </div>
                        </MDBCol>
                      ))}
                    </MDBRow>
                  </MDBContainer>
                </MDBAnimation>
              </section>
            ))}
          </>
        )}

        {/* <SupportCountries /> */}

      </main>
    </Layout>
  )
}
export default Support

export function Head({data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(frontmatter: { name: { eq: "Support" } }) {
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        openingpara
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
        section {
          title
          type
          feature {
            title
            description
          }
        }
      }
      html
    }
  }
`
