import React from 'react'
import Link from 'gatsby-link'
import { MDBCol } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"
import Showdown from 'showdown'

class CardIcons extends React.Component {
  render() {
    const converter = new Showdown.Converter()    
    return (
      <MDBCol lg={this.props.collg} md={this.props.colmd} className="pb-30 d-flex" >
        <div className="card flex-fill outline card-hover">
          <div className="card-body">
            <div style={{ minWidth: '85px', maxWidth: '85px', }} >
              <GatsbyImage
                image={this.props.data.image.childImageSharp.gatsbyImageData}
                className="mr-1"
                alt={this.props.data.alttext}
              />
            </div>

            <div>
              <h3 className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large py-3">
                {this.props.data.title}
              </h3>
              {this.props.data.subtitle && (
                <div className="font-w-400 text-medium"
                  dangerouslySetInnerHTML={{__html: converter.makeHtml(this.props.data.subtitle)}}
               />
              )}
              {this.props.data.features &&
                <>                
                  {this.props.data.features.map((features, index) => (                    
                    <div className="font-w-400 text-medium" key={index}>
                      {features.link.substring(0, 4) !== "http" ?
                        <Link to={features.link} className={features.class} state={{btn: features.btnactive ? features.btnactive : "all"}}>{features.linktext}</Link>
                        :
                        <a href={features.link} target="_blank" className="effect">{features.linktext}</a>
                      }
                    </div>
                  ))}
                </>
              }
            </div>
          </div>
        </div>
      </MDBCol>
    )
  }
}

export default CardIcons
