import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import { renderIcon } from '../utils/helpers.js'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Compatibility from '../components/compatibility'
import Showdown from 'showdown'


const CompatibilityTemplate = ({ data }) => {
  const post = data.markdownRemark
  const matrixKey = [
    "Product will install using operating system's built in driver. Compatibility does not include additional software bundled with the product.", 
    "Product will install using files (drivers) available for download from the USRobotics website or CD. Click on the Product Code to go to the support page to download.", 
    "Product does not depend on operating system to function.", 
    "Product currently is not supported, may be pending qualification, or has been discontinued."
    ]
  const matrixIcon = ["os", "usr", "no dependency", "not supported"]
  const converter = new Showdown.Converter()

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />
      </div>

      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <div
                className={`font-w-400 text-xs-extra-large divlink ${post.html ? 'text-opening-para' : 'text-opening-para-mb0'}`}
                dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.openingpara), }}
              />

              {post.html && (
                <div
                  className="richtext divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              )}
            </MDBCol>
          </MDBRow>

          <MDBRow className="pt-3">
            <MDBCol>
              {matrixKey.map((keyItem, index)=> (
                <MDBRow key={index} className="pb-1 mx-1">
                  <MDBCol xs="1" className="text-center">{renderIcon(matrixIcon[index])}</MDBCol>
                  <MDBCol className="text-small">{keyItem}</MDBCol>
                </MDBRow>
              ))}
            </MDBCol>
          </MDBRow>

          <Compatibility data={post.frontmatter.section} type={post.frontmatter.type} />

          {post.frontmatter.footnote && 
            <div
              className="pt-5 richtext divlink"
              dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.footnote) }}
            />
          }
        </MDBContainer>
      </section>
    </Layout>
  )
}
export default CompatibilityTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "compatibility" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description 
        }
        name
        title
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        type
        openingpara
        footnote
        section {
            title
            server: feature {
              title 
              description
              ws2008
              ws2011
            }
            feature {
              title 
              description
              w78
              w7864
              ws2008
              ws2011
              ws2012
              w10
              ws2016
              ws2019
              w11
          }
        }
      }
      html
    }
  }
`