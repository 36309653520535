import React from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import FormSupportInformation from '../components/formSupportInformation'
import FormProductRegistration from '../components/formProductRegistration'
import FormSalesInformation from '../components/formSalesInformation'

const FormTemplate = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage="null"
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />
      </div>

      <section className="bg-white pb-0">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <div
                className="mt-3 richtext divlink"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <section className="bg-white mt-n3">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
            {post.fields.slug == '/support/support-information/' && (
              <FormSupportInformation />
            )}

            {post.fields.slug == '/support/sales-support/' && (
              <FormSalesInformation />
            )}

            {post.fields.slug == '/support/product-registration/' && (
              <FormProductRegistration />
            )}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </Layout>
  )
}
export default FormTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query($slug: String!) {    
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "form" } }
    ) {
      fields {
        slug 
      }
      html
      frontmatter {
        meta {
          title
          description 
        }        
        title
        subtitle
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
      }
    }
  }
`
