import React from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import Showdown from 'showdown'

library.add(fat)

const InternationalPage = ({ data }) => {
  const post = data.markdownRemark
  const converter = new Showdown.Converter() 

  return (
    <div id="sub-page">
      <Layout>
        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}             
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
              <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{__html: post.frontmatter.openingpara}}
                />
                <div
                  className="mt-3 richtext images divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.international.map((international, index) => (
          <section className="bg-light-blue no-bottom" key={index}>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="4" className="pr-5">
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                    {international.title}
                  </h2>
                </MDBCol>

                <MDBCol md="8">
                  <MDBRow>
                    {international.contact.map((contact, index) => (
                      <MDBCol md="6" className="pb-5 item-prods" key={index}>
                        <div className="int-contact-icon">
                          <FontAwesomeIcon
                            icon={['fat', contact.icon]}
                            size="3x"
                          />
                        </div>
                        <p className="text-medium text-blue-dark font-w-700"> {contact.title} </p>
                        <div className="text-medium text-gray-dark" dangerouslySetInnerHTML={{ __html: converter.makeHtml(contact.email) }} />
		                    <div className="text-medium text-gray-dark" dangerouslySetInnerHTML={{ __html: converter.makeHtml(contact.website) }} />
                      </MDBCol>
                    ))}
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
        ))}        
      
      </Layout>
    </div>
  )
}
export default InternationalPage

export function Head({data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {   
    markdownRemark(      
        frontmatter: { name: { eq: "International" } }
    ) {
      frontmatter {
        meta {
          title
          description 
        }        
        title
        subtitle
        openingpara
        alttext       
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        international {
          title
          contact {
            title
            icon
            email
            website
          }
        }   
      }
      html
    }
  }
`
