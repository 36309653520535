import React from 'react'
import Link from 'gatsby-link'
import { GatsbyImage } from "gatsby-plugin-image"
import { MDBCol, MDBCard, MDBCardUp, MDBAvatar, MDBCardBody, MDBCardText } from 'mdbreact'

class CardResources extends React.Component {
  render() {
    const colHeight = this.props.height != null ? this.props.height : 'auto'
    return (
      <MDBCol
        lg={this.props.collg}
        md={this.props.colmd}
        className="pb-card d-flex"
      >
        <MDBCard className="flex-fill" testimonial>
          <MDBCardUp className={this.props.colour ? this.props.colour : "blue-gradient"} />
          <MDBAvatar className="mx-auto white">
          <GatsbyImage
              top
              image={this.props.image}
              overlay="white-slight"
              hover
              waves
              alt={this.props.alt}
              className="rounded-top drop-shadow"
            />
          </MDBAvatar>
          <MDBCardBody>
            <div style={{ maxHeight: colHeight, minHeight: colHeight }}>
              <h3 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-medium mt-2">
                {this.props.title}
              </h3>
              {this.props.subtitle != '' && this.props.subtitle != null && (
                <h4 className="font-w-400 text-xs-medium text-medium mt-2">
                  {this.props.subtitle}
                </h4>
              )}
            </div>
            <hr />
           
            {this.props.link ? (
              <MDBCardText className="font-w-400 text-medium mt-2 mb-2">
                {this.props.link.substring(0, 4) != 'http' ? (
                  <Link 
                  to={(this.props.link.indexOf('?') > -1 || this.props.link.indexOf('#') > -1 || this.props.link.slice(-1) == "/") ? this.props.link : '' + `/${this.props.link}/`}  className="effect">
                    {this.props.description}
                  </Link>
                ) : (
                  <a
                    href={this.props.link}
                    className="effect"
                    target="_blank"
                    rel="noopener"
                  >
                    {this.props.description}
                  </a>
                )}
              </MDBCardText>
            ) : (
              <MDBCardText className="font-w-400 text-medium mt-2 mb-2"></MDBCardText>
            )}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    )
  }
}

export default CardResources