import React from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import Card from '../components/card'
import Cta from '../components/cta'
import Requirements from '../components/requirements'

const RootTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const btnId = (location.state != null && location.state.btn != null) ? location.state.btn : post.frontmatter.requirements ? post.frontmatter.requirements[0].subtitle : ""

  return (
    <div id="sub-page">
      <Layout>
        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}             
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
              <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{__html: post.frontmatter.openingpara}}
                />
              {post.frontmatter.requirements && 
                <Requirements data={post.frontmatter.requirements} btnId={btnId} /> 
              }
              <div
                className="mt-3 richtext images divlink"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        

        {post.frontmatter.section && (
          <section className="bg-light-blue">
            <MDBContainer>
              <MDBRow>
                <>
                  {post.frontmatter.section.map(sections => {
                    return (
                      <Card
                        collg="4"
                        colmd="6"
                        height="13.5rem"
                        title={sections.title}
                        subtitle={sections.subtitle}
                        description={sections.description}
                        image={sections.image.childImageSharp.gatsbyImageData}
                        alt={sections.alttext}
                        placement={sections.placement}
                        link={sections.link}
                      />
                    )
                  })}
                </>
              </MDBRow>
            </MDBContainer>
          </section>
        )}

        {post.frontmatter.cta && 
          <Cta
            ctatitle={post.frontmatter.cta.title}
            ctadescription={post.frontmatter.cta.description}
            ctalink={post.frontmatter.cta.link}
            ctalinktext={post.frontmatter.cta.linktext}
          />
        }
      
      </Layout>
    </div>
  )
}
export default RootTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query($slug: String!) { 
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "root" } }
    ) {
      frontmatter {
        meta {
          title
          description 
        }        
        title
        subtitle
        openingpara
        alttext
        ctatext
        ctaslug         
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        section {
          title
          subtitle
          description
          image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
          placement
          alttext
          link
        }
        requirements {
          subtitle
          description
        }        
        cta {
          title
          description
          linktext
          link
        }        
      }
      html
    }
  }
`
