import React from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer, MDBAnimation } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import Card from '../components/card'

const Compliance = ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <div id="sub-page">
      <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />
      </div>

      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <div
                className="mt-3 richtext divlink"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      {post.frontmatter.helpfulresources && (
          <section className="bg-white-grey-gradient">
            <MDBAnimation reveal type="fadeIn" duration="1587ms">
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                  {' '}
                  {post.frontmatter.helpfulresources.title}{' '}
                </h2>
                <p className="text-medium">
                  {post.frontmatter.helpfulresources.subtitle}
                </p>
                {post.frontmatter.helpfulresources.helpfulres && (
                  <MDBRow className="pt-5">
                    <>
                      {post.frontmatter.helpfulresources.helpfulres.map(
                        (helpfulres) => {
                          return (
                            <Card
                              collg="4"
                              colmd="6"
                              height="11.5rem"
                              title={helpfulres.title}
                              subtitle={helpfulres.subtitle}
                              description={helpfulres.description}
                              image={
                                helpfulres.image.childImageSharp.gatsbyImageData
                              }
                              alt={helpfulres.alttext}
                              placement={helpfulres.placement}
                              link={
                                helpfulres.link
                                  ? helpfulres.link
                                  : helpfulres.document.publicURL
                              }
                              titleclass="title-small"
                              descriptionClass="text-card-small py-2"
                            />
                          )
                        }
                      )}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        )}      
    </Layout>
  )
}

export default Compliance

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}


export const query = graphql`
  query($slug: String!) { 
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "compliance" } }
    ) {
      fields {
        slug
      }
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        alttext
        helpfulresources {
          title
          subtitle
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            document {
              publicURL
            }
          }
        }        
      }
      html
    }
  }
`
