import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, toast, ToastContainer } from 'mdbreact'
import { Zoom } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { GoogleMap, LoadScript } from '@react-google-maps/api'
import { Marker } from '@react-google-maps/api'
import axios from 'axios'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faEnvelope, faGlobe } from '@fortawesome/pro-solid-svg-icons'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name must be 2 characters at minimum')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'),
  country: Yup.string()
    .min(2, 'Name must be 2 characters at minimum')
    .required('Country is required'),
  message: Yup.string()
    .min(5, 'Message must be 5 characters at minimum')
    .required('Message is required'),
  //subscribe: Yup.bool().oneOf([true], 'Terms and conditions need to be accepted'),
  })

const formUrl = 'https://script.google.com/macros/s/AKfycbww7glFGHSgpGqKk0S0pOFZwk5ORnqYqhuiJK7Lx8YDmRCPQjuwBBpY5oPL5L3D_TE33w/exec'

const messageConfirm = () => {
  return toast.info("Thank you for contacting us.  We'll be in touch shortly!", { closeButton: false, position: toast.POSITION.TOP_CENTER, transition: Zoom })
}

class ContactMain extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      pageUrl: null,
      load: false,
      expired: 'false',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ 
        load: true,
        pageUrl: location.href 
      })
    })
  }

  handleChange = value => {
    this.setState({ value })
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
      <section className="bg-gray-light">
      <MDBContainer>
        <MDBRow>
          <MDBCol md="6" className="md-0 mb-5">
            <Formik
              initialValues={{
                name: '',
                email: '',
                country: '',
                message: '',
                subscribe: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, {setSubmitting, resetForm}) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  Name: values.name,
                  Email: values.email,
                  Country: values.country,
                  Message: values.message,
                  Subscribe: values.subscribe,
                }
                try {
                  const response = axios({
                      method: 'get',
                      mode: 'no-cors',
                      url: `${formUrl}?timestamp=&url=${encodeURIComponent(this.state.pageUrl)}&name=${encodeURIComponent(values.name)}&email=${encodeURIComponent(values.email)}&country=${encodeURIComponent(values.country)}&message=${encodeURIComponent(values.message)}&subscribe=${encodeURIComponent(values.subscribe)}`
                    })
                      this.setState({values: '', expired: 'true'})
                      resetForm()
                      messageConfirm()
                    } catch (e) {
                      console.log(`Axios request failed: ${e}`)
                    }
                  setSubmitting(false)
                }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form
                  name="contact"
                  method="post"
                  action=""
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />
                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="name">
                          Name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="name"
                          name="name"
                          aria-label="name"
                          value={values.name}
                          placeholder="Your full name"
                          className={`form-control ${
                            touched.name && errors.name ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="name"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="country">
                          Country <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="text"
                          name="country"
                          aria-label="country"
                          value={values.country}
                          placeholder="Your country of residence"
                          className={`form-control ${
                            touched.country && errors.country
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="message"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="email">
                          Email <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="email"
                          name="email"
                          aria-label="email"
                          value={values.email}
                          placeholder="Your email address"
                          className={`form-control ${
                            touched.email && errors.email ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="message">Message</label>
                        <Field
                          type="textarea"
                          component="textarea"
                          name="message"
                          aria-label="message"
                          value={values.message}
                          placeholder="Enter message"
                          className={`form-control ${
                            touched.message && errors.message
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="message"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                        To receive occasional updates about USR's
                        solutions, services and news, please tick the box
                        below. You can unsubscribe at any time.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mt-3 mb-4 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="subscribe"
                          name="subscribe"
                          aria-label="subscribe"
                          className={`form-check-input ${
                            touched.subscribe && errors.subscribe
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <label htmlFor="subscribe">
                          Yes, I would like to receive communications from
                          USR.
                        </label>
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-400 text-small">
                        For details on how your data is stored and used, please
                        visit our{' '}
                        <Link to="/privacy-statement/" className="effect">
                          Privacy Statement
                        </Link>
                        .
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12" className="mb-4">
                      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '120px' }}>
                        <button
                          type="submit"
                          className="mt-4 btn btn-medium btn-unicom"
                          style={{ display: 'block' }}
                          disabled={ isSubmitting ? true : false }
                        >
                          {isSubmitting ? 'Please wait...' : 'Submit'}
                        </button>
                        <ToastContainer
                          transition={Zoom}
                          hideProgressBar={true}
                          newestOnTop={true}
                          autoClose={5000}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBCol>

          <MDBCol md="6" className="contact-address">
          <h2 className="font-alt font-w-700 letter-spacing-1 my-2 pl-3 title-xs-medium title-large">HQ</h2>
            <p className="font-w-400 text-medium mt-2 mb-3 pl-3">
              USR
              <br />
              231 N. Martingale Road, Suite: 100
              <br />
              Schaumburg
              <br />
              IL 60173
              <br />
              United States
            </p>

            <MDBRow>
              <MDBCol lg="12" md="12">
                <p className="font-w-400 text-medium mt-2 ml-3 mb-3">
                  <FontAwesomeIcon icon={faPhoneAlt} className="mr-3 va-m" />+1 847 874 2000
                </p>

              </MDBCol>
            </MDBRow>

            <MDBCol md="12" className="mt-4 mb-3">
              <div
                id="map-container"
                className="rounded z-depth-1-half map-container"
                style={{ height: '400px' }}
              >
                <LoadScript
                  id="script-loader"
                  googleMapsApiKey="AIzaSyCpXjW8NbD3JZOylLfE3vYEBj1spgmovhU"
                >
                  <GoogleMap
                    id="example-map"
                    mapContainerStyle={{
                      height: '400px',
                      width: '100%',
                    }}
                    zoom={15}
                    center={{
                      lat: 42.0337574,
                      lng: -88.0331181,
                    }}
                  >
                    <Marker
                      onLoad={marker => {}}
                      position={{
                        lat: 42.0337574,
                        lng: -88.0331181,
                        title: 'USR Global HQ',
                      }}
                    />
                  </GoogleMap>
                </LoadScript>
              </div>
            </MDBCol>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      </section>
    )
  }
}

export default ContactMain
