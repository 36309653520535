import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import { MDBContainer, MDBAnimation } from 'mdbreact'
import ContactMain from '../components/contactMain'
import OfficeLocations from '../components/columnAlternatingOffices'

const Contact = ({ data }) => {
  const heroImage = data.heroImage
  const post = data.markdownRemark

  return (
    <>
      <Layout>
        <div id="sub-page">
          <Hero
            class="intro-65"
            image={heroImage.childImageSharp.gatsbyImageData}
            title="Need to get in touch?"
            subtitle="Fill out the form below and we'll get back to you."
            type="contact"
            alt="hand holding a paper plane ready to throw"
          />
        </div>

        <main>
          
          <ContactMain />
          
          {post.frontmatter.locations && (
            <section className="bg-light-blue no-top">
              <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
                <MDBContainer>
                  <h3 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-large py-5">
                    {post.frontmatter.locations.title}
                  </h3>
                  {post.frontmatter.locations.offices.map(
                    (officelocations, index) => {
                      return (
                        <OfficeLocations
                          key={index}
                          office={officelocations.office}
                          address={officelocations.address}
                          tel={officelocations.tel}
                          link={officelocations.link}
                          linktext={officelocations.linktext}
                          lat={officelocations.lat}
                          lng={officelocations.lng}
                          placement={officelocations.placement}
                        />
                      )
                    }
                  )}
                </MDBContainer>
              </MDBAnimation>
            </section>
          )}
        </main>
      </Layout>
    </>
  )
}
export default Contact

export function Head({ data, location }) {
  const heroImage = data.heroImage
  return (
    <SEO
      title="Contact Us"
      description="Want more information on USRs products and services? Please get in touch using the contact details or web form provided."
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {
    markdownRemark(
      fields: {}
      frontmatter: { template: { eq: "other" }, name: { eq: "contact" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        locations {
            title
            offices {
              office
              address
              tel
              link
              linktext
              lat
              lng
              placement
            }
          }        
      }
      html
    }
    heroImage: file(name: { eq: "contact-us" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`
